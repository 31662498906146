import * as React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { SelectInput } from "react-admin";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {
  useListContext,
  CreateButton,
  List,
  Datagrid,
  TextField,
  DateField,
  useTranslate,
  ListActions,
  useRecordContext,
  ImageField,
  ArrayField,
  ChipField,
  SingleFieldList,
  NumberField,
  NumberInput,
  SimpleForm
} from "react-admin";

const Empty = () => {
  const { basePath } = useListContext();
  const translate = useTranslate(); // returns the i18nProvider.translate() method
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        {translate("resources.sponsors.fields.noSponsor")}
      </Typography>
      <CreateButton basePath={basePath} />
    </Box>
  );
};

const TranslateTextField = props => {
  const translate = useTranslate();
  const { source } = props;
  const record = useRecordContext(props);

  return (
    <Chip label={translate("resources.sponsors.fields." + record[source])} />
  );
};

TranslateTextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

const OrderField = props => {
  const { source } = props;
  const record = useRecordContext(props);
  //
  return <span>{record[source]}</span>;
};

OrderField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export const ArticleList = props => {
  return (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} actions={<ListActions />} empty={<Empty />}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="createBy" />
        <ArrayField source="categories">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <OrderField source="isOrder" />
        <DateField source="created_at" showTime />
      </Datagrid>
    </List>
  );
};
