import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  ReferenceInput,
  SelectInput,
  useListContext,
  BooleanField,
  BooleanInput,
  FilterButton,
  TextInput,
  ExportButton,
  SearchInput,
} from "react-admin";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { FaMinusCircle, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { RiMailCloseLine, RiMailSendLine } from "react-icons/ri";
import {Box, makeStyles} from "@material-ui/core";
import _ from "lodash";
import api from "../../feathersClient";
import decodeJwt from 'jwt-decode';

const getToken = () => {
    return decodeJwt(localStorage.getItem("token"));
}

export const DefaultSelectInput = ({
  value,
  defaultYear,
  onChange,
  ...props
}) => {
  const [defaultValue, setDefaultValue] = useState(null);

  // Select most recent year by default.
  useEffect(() => {
    if (defaultValue) return;
    if (defaultYear) {
      const found = _.find(props.choices, { year: defaultYear });
      if (found) {
        setDefaultValue(found._id);
        return;
      }
    }
    if (props.choices.length > 0) {
      setDefaultValue(props.choices[0]._id);
      onChange(props.choices[0].year);
    }
  }, [props.choices, onChange, defaultValue, defaultYear]);

  useEffect(() => {
    if (_.get(props, "input.value") !== value) {
      const found = _.find(props.choices, { year: parseInt(value) });
      if (found) {
        props.reset();
      }
    }
  }, [props, value]);

  return (
    <>
      <SelectInput {...props} defaultValue={defaultValue} value={2017} />
      <OnChange name="year">
        {value => {
          const year = _.find(props.choices, { _id: value }).year;
          onChange(year);
        }}
      </OnChange>
    </>
  );
};

const ListActions = ({ setYear }) => {
  const { setFilters, filterValues } = useListContext();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [user, setUser] = useState({})

  const permissions = user ? user.role : undefined;
  useEffect(() => {
    setYear(_.get(filterValues, "year"));
  }, [setYear, filterValues]);

  useEffect(()=> {
      const user = getToken();
      setUser(user)
  },[]);

  const onChange = value => {
    if (value !== filterValues.year) {
      setFilters({ year: value });
    }
  };

  useEffect(() => {
    if (filterValues.year) {
      api
        .service("competitions")
        .find({ query: { year: filterValues.year } })
        .then(res => {
          if (_.get(res, "data.0.categories")) {
            setCategories(
              res.data[0].categories.map((category, i) => ({
                id: i,
                name: category.name
              }))
            );
          } else {
            setCategories([]);
          }
        });
    }
  }, [filterValues.year]);

  useEffect(() => {
    if (filterValues.year && filterValues.category) {
      api
        .service("competitions")
        .find({ query: { year: filterValues.year } })
        .then(res => {
          const found = _.find(_.get(res, "data.0.categories", {}), {
            name: filterValues.category
          });
          const i = _.findIndex(_.get(res, "data.0.categories", {}), {
            name: filterValues.category
          });
          if (found && i !== -1 && _.get(found, "subcategories")) {
            setSubCategories(
              found.subcategories.map((sub, j) => ({
                id: j,
                name: `${sub.name}`
              }))
            );
          } else {
            setSubCategories([]);
          }
        });
    }
  }, [filterValues.year, filterValues.category]);

  return (
    <Box width="100%">
      <TopToolbar>
        <FilterButton />
        <Form onSubmit={() => {}}>
          {({ handleSubmit, reset }) => (
            <form onSubmit={handleSubmit}>
              <ReferenceInput
                source="year"
                reference="competitions"
                sort={{ field: "year", order: "DESC" }}
              >
                <DefaultSelectInput
                  optionText="year"
                  onChange={onChange}
                  defaultYear={filterValues.year}
                  reset={reset}
                />
              </ReferenceInput>
              <SelectInput
                label="Hạng mục dự thi"
                source="category"
                choices={categories}
                allowEmpty
              />
              <OnChange name="category">
                {value => {
                  const newFilters = {
                    ...filterValues,
                    category: _.get(categories, `${value}.name`)
                  };
                  if (!_.isNumber(value)) delete newFilters.category;
                  delete newFilters.subCategory;
                  setFilters(newFilters);
                }}
              </OnChange>
              {filterValues.category && (
                <SelectInput
                  label="Lĩnh vực dự thi"
                  source="subCategory"
                  choices={subCategories}
                  allowEmpty
                />
              )}
              <OnChange name="subCategory">
                {value => {
                  const newFilters = {
                    ...filterValues,
                    subCategory: _.get(subCategories, `${value}.name`)
                  };
                  if (!_.isNumber(value)) delete newFilters.subCategory;
                  setFilters(newFilters);
                }}
              </OnChange>
            </form>
          )}
        </Form>
        {permissions > 3 && <ExportButton />}
      </TopToolbar>
    </Box>
  );
};

const FileStatusField = props => (
  <FunctionField
    {...props}
    render={record => {
      const file = _.get(record, props.source, {});

      if (!file || !file.url) {
        return null;
      }

      switch (file.status) {
        case "APPROVED":
          return <FaCheckCircle color="green" />;
        case "REJECTED":
          return <FaTimesCircle color="red" />;
        case "NONE":
          return <FaMinusCircle color="orange" />;
        default:
          return null;
      }
    }}
  />
);

const FinalResultField = props => (
  <FunctionField
    {...props}
    render={record => {
      if (record.result && record.finalResult) {
        if (record.result === "WINNER") return <FaCheckCircle color="green" />;
        if (record.result === "LOSER") return <FaTimesCircle color="red" />;
      }
      return null;
    }}
  />
);

const IsMarking = (props) => {
    const [user, setUser] = useState({})

    const getToken = () => {
        return decodeJwt(localStorage.getItem("token"));
    }

    useEffect(()=> {
        const user = getToken();
        setUser(user)
    },[])
    return (<FunctionField
        {...props}
        render={record => {
            const markingData = _.get(record, props.source, {});
            const isMarked =  _.some(markingData, (data, index) => {
                return index === `_${user.id}`
            })
            if(isMarked) {
                const superNotPass = _.get(markingData, `_${user.id}._${props.year}`, {}).superNotPass;
                if(superNotPass === undefined){
                    return null
                }
                return superNotPass ? (<FaTimesCircle color="red" />):(<FaCheckCircle color="green" />);
            } else {
                return null;
            }
        }}
    />
)};

const PrelimResultField = props => (
  <FunctionField
    {...props}
    render={record => {
      const results = [];

      if (record.marking) {
        Object.values(record.marking).forEach(m => {
          if(props.year >= 2024){
              const data = _.get(m, `_${props.year}`, null)
              if(data){
                  if (data.superNotPass === false) {
                      results.push("PASS");
                  } else if (data.superNotPass === true) {
                      results.push("FAIL");
                  }
              }
          }  else {
              if(props.year !== new Date(m.date).getFullYear()) {
                  //onlyshow selected year
                  return
              }

              if (m.superNotPass === false) {
                  results.push("PASS");
              } else if (m.superNotPass === true) {
                  results.push("FAIL");
              }
          }
        });
      }

      return (
        <span style={{ width: "70px", display: "block" }}>
          {results.map((p, i) =>
            p === "PASS" ? (
              <FaCheckCircle key={i} color="green" />
            ) : (
              <FaTimesCircle key={i} color="red" />
            )
          )}
        </span>
      );
    }}
  />
);

const useStyles = makeStyles({
    headerCell: {
        '&:first-child': {
            width: 150
        }
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#def2ff',
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    },
});

export default props => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [filter, setFilter] = useState({ role: { $lt: 1 } });

    const [user, setUser] = useState({})

    const permissions = user ? user.role : undefined;
const classes =useStyles();
  useEffect(() => {
    if (permissions <= 2) {
      filter["entryConfirmed"] = true;
    }
    if (permissions === 2) {
      filter["entryConfirmed"] = true;
      filter["passedPrelim"] = true;
    }

      const user = getToken();
      setUser(user)
  }, [permissions]);

  return (
    <List
      {...props}
      filter={filter}
      filters={
        permissions === 1
          ? [
              <SearchInput source="q" alwaysOn />,
              <BooleanInput source="markedByMe" label="Được đánh giá bởi tôi" />
            ]
          : permissions > 3
          ? [
              <SearchInput source="q" alwaysOn />,
              <BooleanInput
                source="entryConfirmed"
                label="Giám khảo sơ bộ"
                defaultValue={true}
              />,
              <BooleanInput
                source="passedPrelimOrSpecialPass"
                label="Giám khảo chung khảo"
                defaultValue={true}
              />
            ]
          : [ <SearchInput source="q" alwaysOn />,<TextInput source="username" />]
      }
      actions={<ListActions setYear={setYear} />}
      // sort={{ field: "updatedAt", order: "DESC" }}
      {...(permissions < 4 ? { bulkActionButtons: false } : {})}
    >
      <Datagrid rowClick="edit" classes={{ headerCell: classes.headerCell, row: classes.row }}>

        <TextField source="name" style={{ wordBreak: "break-all" }} />
        {/*{(permissions === 2 || permissions > 3) && (*/}
          {(permissions > 3) && (
          <PrelimResultField label="Kết quả sơ khảo" year={year}/>
        )}
        {(permissions === 2 || permissions > 3) && (
          <FinalResultField label="Kết quả chung khảo"  year={year}/>
        )}
          {permissions === 1 && (
              <IsMarking label="Đánh giá" source="marking" year={year}/>
          )}
        <FileStatusField label="1. Đơn đăng ký" source={`_${year}_document1`} />
        <FileStatusField
          label="2. Giấy phép ĐKKD / CMND"
          source={`_${year}_document2`}
        />
        <FileStatusField
          label="3. Báo cáo mô tả sản phẩm"
          source={`_${year}_document3`}
        />
        <FileStatusField
          label="4. Kết quả kinh doanh"
          source={`_${year}_document4`}
        />
        <FileStatusField
          label="5. Bản sao ĐKKD (nếu có)"
          source={`_${year}_document5`}
        />
        <FileStatusField
          label="6. Xác nhận của thuế (nếu có)"
          source={`_${year}_document6`}
        />
        <FileStatusField
          label="7. BC môi trường (nếu có)"
          source={`_${year}_document7`}
        />
        <FileStatusField
          label="8. BC kết quả hoạt động (nếu có)"
          source={`_${year}_document8`}
        />
        <FileStatusField
          label="9. Các loại chứng nhận (nếu có)"
          source={`_${year}_document9`}
        />
        <FileStatusField
          label="10. Ý kiến đánh giá (nếu có)"
          source={`_${year}_document10`}
        />
        <BooleanField
          label="Giám khảo sơ bộ"
          source="entryConfirmed"
          looseValue={true}
          TrueIcon={() => <RiMailSendLine size={20} color="green" />}
          FalseIcon={() => <RiMailCloseLine size={20} />}
        />
        <BooleanField
          label="Gửi giám khảo chung khảo"
          source="passedPrelim"
          looseValue={true}
          TrueIcon={() => <RiMailSendLine size={20} color="green" />}
          FalseIcon={() => <RiMailCloseLine size={20} />}
        />
      </Datagrid>
    </List>
  );
};
