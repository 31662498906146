import * as React from "react";
import {
  Create,
  CreateProps,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageField,
  required,
  ImageInput,
  TextField,
} from "react-admin";
import { Box, CardContent, Divider, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import { CheckboxGroupInput } from "react-admin";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import feathersClient from "../../feathersClient";
import MarkDownInputWithField from "../../components/MarkDownInputWithField";

const useStyles = makeStyles({
  createBy: {
    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
  },
  chip: {
    marginTop: 16,
    width: "unset",
  },
});

export const ArticleCreate = (props) => {
  const [category, setCategory] = React.useState([]);
  const [order, setOrder] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});
  const classes = useStyles();

  React.useEffect(async () => {
    axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 7000,
      })
      .get("/categories")
      .then((data) => {
        if (data && data.data && data.data.total > 0) {
          setCategory(
            data.data.data.map((item) => ({ ...item, id: item._id }))
          );
        }
      })
      .finally(async () => {
        const data = await feathersClient.get("authentication");
        setCurrentUser(data.user);
      });
  }, []);

  React.useEffect(() => {
    axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 7000,
      })
      .get("/articles?isOrder[$gte]=1")
      .then((data) => {
        const orders = [1, 2, 3, 4, 5, 6];

        const toRemove = new Set();

        // if(data && data.data)
        // const difference =
        if (data && data.data && data.data.total > 0) {
          data.data.data.forEach((item) => toRemove.add(item.isOrder));

          const newOrder = orders.filter((x) => !toRemove.has(x));

          const mapped = newOrder.map((number) => ({
            id: number.toString(),
            name: number.toString(),
          }));
          setOrder(mapped);
        } else {
          const mapped = orders.map((number) => ({
            id: number.toString(),
            name: number.toString(),
          }));
          setOrder(mapped);
        }
      });
  }, []);

  return (
    <Create {...props} actions={false}>
      <SimpleForm component={CustomLayout} redirect="show">
        <ImageInput source="avatar">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="title" validate={required()} fullWidth />
        <MarkDownInputWithField source="body" fullWidth />
        <CheckboxGroupInput source="categories" choices={category} />
        <div>Tác giả</div>
        <TextInput
            source="createBy"
            className={classes.createBy}
            fullWidth
        />
        <SelectInput
          style={{ marginTop: 24 }}
          source="isOrder"
          choices={[{ id: "", name: "None" }, ...order]}
        />

      </SimpleForm>
    </Create>
  );
};

const CustomLayout = (props) => (
  <CardContent>
    <Box display="flex">
      <Box paddingTop={1}>
        <Avatar>
          <BusinessIcon />
        </Avatar>
      </Box>
      <Box ml={2} flex="1" maxWidth={796}>
        {props.children}
      </Box>
    </Box>
  </CardContent>
);
