import React, { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip, makeStyles,
} from "@material-ui/core";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { GoVerified, GoUnverified } from "react-icons/go";
import { Form } from "react-final-form";
import { CompactForm, RaGrid as Grid } from "ra-compact-ui";
import {
  BooleanInput,
  FileField,
  Edit,
  TextField,
  SelectInput,
  ReferenceInput,
  Labeled,
  TextInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  FormDataConsumer,
  NullableBooleanInput,
  DateInput,
  useRecordContext,
  BooleanField,
} from "react-admin";
import _ from "lodash";
import { DefaultSelectInput } from "./UsersList";
import "./style.css";
import api from "../../feathersClient";
import decodeJwt from "jwt-decode";
import moment from "moment";

const UserTitle = ({ record }) => {
  return (
    <span style={{ display: "flex", flexDirection: "row" }}>
      <Avatar
        src={_.get(record, "avatar.url")}
        style={{ height: "35px", width: "35px", marginRight: "8px" }}
      />
      {record ? `${record.username}` : ""}
    </span>
  );
};

const EditActions = ({ setYear }) => {
  return (
    <>
      <Form onSubmit={() => {}}>
        {({ handleSubmit, reset }) => (
          <form onSubmit={handleSubmit}>
            <ReferenceInput
              source="year"
              reference="competitions"
              sort={{ field: "year", order: "DESC" }}
            >
              <DefaultSelectInput optionText="year" onChange={setYear} />
            </ReferenceInput>
          </form>
        )}
      </Form>
    </>
  );
};

const DocumentInput = (props) => {
  const [user, setUser] = useState({})

  const permissions = user ? user.role : undefined;
  useEffect(()=> {
    const user = getToken();
    setUser(user)
  },[]);

  return (
    <Labeled {...props} fullWidth={true} style={{ backgroundColor: "red" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <FileField {...props} fullWidth={true} />
        {permissions > 3 && _.get(props.record, props.source) && (
          <SelectInput
            {...props}
            label=""
            source={props.source.replace("url", "status")}
            choices={[
              { id: "NONE", name: "Chưa được kiểm tra" },
              { id: "REJECTED", name: "Từ chối" },
              { id: "APPROVED", name: "Chấp thuận" },
            ]}
            allowEmpty
          />
        )}
      </div>
    </Labeled>
  );
};

const getToken = () => {
  return decodeJwt(localStorage.getItem("token"));
}

const CustomToolbar = (props) => {
  const [user, setUser] = useState({})

  const permissions = user ? user.role : undefined;
  useEffect(()=> {
    const user = getToken();
    setUser(user)
  },[]);

  const sendIt = async () => {
    try {
      await api
        .service("users")
        .patch(props.record._id, { entryConfirmed: true });
      window.location.href = "/#/users";
    } catch (e) {
      // TODO: Handle error.
    }
  };

  const form = useForm();
  var formdata = form.getState().values;

  return permissions == 3 ? null : (
    <Toolbar {...props}>
      <SaveButton />
      <div style={{ width: "32px" }} />
      {permissions == 2 && (
        <Button
          disabled={!formdata.result || props.record.finalResult}
          variant="contained"
          style={{ whiteSpace: "nowrap", minWidth: "max-content" }}
          onClick={async () => {
            try {
              await api
                .service("users")
                .patch(props.record._id, { finalResult: true });
              props.handleSubmitWithRedirect();
            } catch (e) {
              // pass
            }
          }}
        >
          Kết quả chung cuộc
        </Button>
      )}
      <div style={{ width: "100%" }} />
      {permissions > 2 && (
        <DeleteWithConfirmButton confirmTitle="Xác nhận xóa" />
      )}
    </Toolbar>
  );
};

const Cells = (props) => {
  const record = useRecordContext(props);
  const [markings, setMarkings] = useState([[], [], [], [], [], [], []]);

  useEffect(async () => {
    if (!record) return;

    const newMarkings = [[], [], [], [], [], [], []];
    const judges = await api.service("users").find({
      query: {
        role: 1,
        $limit: 10000,
        $select: ["_id", "givenName", "familyName"],
      },
    });
    judges.data.forEach((judge) => {
      [1, 2, 3, 4, 5, 6, 7].forEach((i) => {
        const notPass = _.get(
          record,
          `marking._${judge._id}._${props.year}._${i}.notPass`,
          -1000
        );
        const getMarkingData = _.get(
            record,
            `marking._${judge._id}`);

        if (notPass !== -1000) {
          const date = _.get(getMarkingData, `_${props.year}.date`, null)

          if(date) {
            const markingYear = moment(date);
            if (markingYear.year() === new Date().getFullYear()) {
              newMarkings[i - 1].push({
                name: judge.familyName + " " + judge.givenName,
                pass: !notPass, // FIXME: NullableBoolean True/False values are swapped around so they show in a different order.
                // Therefore, pass actually means fail and fail means pass.
              });
            }
          }
        }
      });
    });

    setMarkings(newMarkings);
  }, [record]);

  return markings.map((marking, i) => {
    return (
      <TableCell key={i}>
        {marking.map((ms, i) => {
          return (
            <Tooltip key={i} title={ms.name}>
              <a>
                {ms.pass ? (
                  <FaCheckCircle color="green" />
                ) : (
                  <FaTimesCircle color="red" />
                )}
              </a>
            </Tooltip>
          );
        })}
      </TableCell>
    );
  });
};



export default (props) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [user, setUser] = useState(null);
  const  permissions  = user ? user.role : undefined;
  const [categories, setCategories] = useState({});

  useEffect(async () => {
    if (user) {
      return;
    }

    try {
      const res = await api.reAuthenticate();
      setUser(res.user);
    } catch (e) {
      // pass
    }

    api
        .service("competitions")
        .find({ query: { "year" : year } })
        .then(res => {
          console.log("res", res.data, year)
          if (_.get(res, "data.0.categories")) {
            setCategories(res.data[0]);
          } else {
            setCategories({});
          }
        });
  }, []);

  const todayDate = new Date();

  return (
    <Edit
      {...props}
      actions={<EditActions setYear={setYear} />}
      title={<UserTitle />}
    >
      <CompactForm layoutComponents={[Grid]} toolbar={<CustomToolbar />}>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <TextField source="email" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <BooleanField
              source="isVerified"
              TrueIcon={() => <GoVerified size={20} color="green" />}
              FalseIcon={() => <GoUnverified size={20} color="red" />}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h2>I. Hồ sơ dự thi</h2>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <TextField source="category" />
            <TextField source="productName" />
            <TextField source="name" />
            {permissions <= 3 ? <></> : <TextField source="profileEmail" />}
            {permissions <= 2 ? <></> : <TextField source="address" />}
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField source="subCategory" />
            <TextField source="subject" />
            <TextField source="idNumber" />
            {permissions <= 3 ? <></> : <TextField source="phoneNumber" />}
            {permissions <= 3 ? <></> : <TextField source="vdaAttendee" />}
          </Grid>
          <Grid item sm={6} xs={12}>
            {permissions == 3 ? (
              <></>
            ) : (
              <DocumentInput
                number={1}
                label="1. Đơn đăng ký"
                source={`_${year}_document1.url`}
                title={`_${year}_document1.name`}
                target="_blank"
              />
            )}
            <DocumentInput
              number={2}
              label="2. Giấy phép ĐKKD / CMND"
              source={`_${year}_document2.url`}
              title={`_${year}_document2.name`}
              target="_blank"
            />
            <DocumentInput
              number={3}
              label="3. Báo cáo mô tả sản phẩm dịch vụ"
              source={`_${year}_document3.url`}
              title={`_${year}_document3.name`}
              target="_blank"
            />
            <DocumentInput
              number={4}
              label="4. Kết quả kinh doanh / ứng dụng sản phẩm dịch vụ"
              source={`_${year}_document4.url`}
              title={`_${year}_document4.name`}
              target="_blank"
            />
            <DocumentInput
              number={5}
              label="5. Các bản sao công chứng đăng ký kinh doanh, giấy phép hoặc tương đương (nếu có)"
              source={`_${year}_document5.url`}
              title={`_${year}_document5.name`}
              target="_blank"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <DocumentInput
              number={6}
              label="6. Xác nhận của cơ quan thuế, BHXH, BHYT trong 3 năm (nếu có)"
              source={`_${year}_document6.url`}
              title={`_${year}_document6.name`}
              target="_blank"
            />
            <DocumentInput
              number={7}
              label="7. Báo cáo kết quả đánh giá hoạt động môi trường (nếu có)"
              source={`_${year}_document7.url`}
              title={`_${year}_document7.name`}
              target="_blank"
            />
            <DocumentInput
              number={8}
              label="8. Báo cáo kết quả hoạt động ứng dụng KHCN, ứng dụng CNTT (nếu có)"
              source={`_${year}_document8.url`}
              title={`_${year}_document8.name`}
              target="_blank"
            />
            <DocumentInput
              number={9}
              label="9. Các loại chứng nhận, chứng chỉ về chất lượng sản phẩm"
              source={`_${year}_document9.url`}
              title={`_${year}_document9.name`}
              target="_blank"
            />
            <DocumentInput
              number={10}
              label="10. Ý kiến đánh giá, nhận xét của cơ quan quản lý nhà nước địa phương hoặc của Tổ chức/cá nhân đề cử (nếu có)"
              source={`_${year}_document10.url`}
              title={`_${year}_document10.name`}
              target="_blank"
            />
          </Grid>
        </Grid>
        <FileField
          label="Tệp bổ sung"
          source={`_${year}_extraFiles`}
          src="url"
          title="name"
        />
        {permissions === 1 ? (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!user || !rest.record.entryConfirmed) {
                return null;
              }

              const Header = () => (
                <Grid container>
                  <Grid item xs={1}>
                    <h3>STT</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <h3>Tiêu chí chấm điểm</h3>
                  </Grid>
                  <Grid item xs={2}>
                    <h3>Kết quả</h3>
                  </Grid>
                  <Grid item xs={3}>
                    <h3>Ý kiến khác</h3>
                  </Grid>
                </Grid>
              );

              //List all tiêu chí chấm điểm
              const findOne = _.find(categories.categories, {"name" : rest.record.category});
              console.log("findOne", findOne, rest.record)
              const rows = findOne ? _.map(findOne.scoringcriteria, (item) => item.name) : [""]

              return (
                <>
                  <h2>II. KẾT QUẢ CHẤM THI VÒNG SƠ KHẢO</h2>
                  <Grid container>
                    <Grid item xs={12}>
                      <DateInput
                        {...rest}
                        disabled
                        label="Thời gian chấm thi"
                        source={`marking._${user._id}._${year}.date`}
                        defaultValue={todayDate}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        {...rest}
                        disabled
                        label="Giám khảo"
                        source={`marking._${user._id}._${year}.name`}
                        defaultValue={`${user.familyName} ${user.givenName} (${user.username})`}
                      />
                    </Grid>
                  </Grid>
                  <Header />
                  {rows.map((name, i) => {
                    return (
                      <Grid key={i + 1} container>
                        <Grid item xs={1}>
                          <p>{i + 1}.</p>
                        </Grid>
                        <Grid item xs={6}>
                          <p style={{ width: "98%" }}>{name}</p>
                        </Grid>
                        <Grid item xs={2}>
                          <NullableBooleanInput
                            {...rest}
                            label=""
                            fullWidth
                            style={{ width: "98%" }}
                            source={`marking._${user._id}._${year}._${i + 1}.notPass`}
                            nullLabel=" "
                            trueLabel="Không đạt"
                            falseLabel="Đạt"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            {...rest}
                            multiline
                            style={{ width: "98%" }}
                            label=""
                            source={`marking._${user._id}._${year}._${i + 1}.otherIdea`}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <NullableBooleanInput
                    {...rest}
                    label="Kết quả sơ khảo"
                    fullWidth
                    style={{ width: "250px" }}
                    source={`marking._${user._id}._${year}.superNotPass`}
                    nullLabel=" "
                    trueLabel="Không đạt"
                    falseLabel="Đạt"
                  />
                </>
              );
            }}
          </FormDataConsumer>
        ) : permissions === 2 || permissions > 3 ? (
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (!user || !rest.record.entryConfirmed) {
                return null;
              }
              //List all tiêu chí chấm điểm
              const findOne = _.find(categories.categories, {"name" : rest.record.category});
              const cols = ["Tên sản phẩm"].concat(
                  findOne ? _.map(findOne.scoringcriteria, (item) => item.name) : [""]
              );

              return (
                <>
                  {permissions > 3 && <h2>II. KẾT QUẢ CHẤM THI VÒNG SƠ KHẢO</h2>}
                  {permissions > 3 &&<TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {cols.map((col) => {
                            return <TableCell key={col}>{col}</TableCell>;
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{rest.record.productName}</TableCell>
                          <Cells year={year} {...rest} />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>}
                  <h2>III. KẾT QUẢ CHẤM THI VÒNG CHUNG KHẢO</h2>
                  <SelectInput
                    label="Chiến thắng/ Loại bỏ"
                    required={true}
                    allowEmpty={!rest.record.finalResult}
                    source="result"
                    choices={[
                      { id: "WINNER", name: "Chiến thắng̉" },
                      { id: "LOSER", name: "Loại bỏ" },
                    ]}
                    style={{ width: "100%" }}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        ) : null}
        {permissions > 3 && <TextInput fullWidth multiline source="notes" />}
        {permissions > 3 && (
          <BooleanInput fullWidth multiline source="showOnWebsite" />
        )}
        {permissions > 3 && (
          <BooleanInput
            label="Giám khảo sơ bộ"
            fullWidth
            multiline
            source="entryConfirmed"
          />
        )}
        {permissions > 3 && (
          <BooleanInput
            label="Gửi giám khảo chung khảo"
            fullWidth
            multiline
            source="passedPrelim"
          />
        )}
      </CompactForm>
    </Edit>
  );
};
