import React, { useEffect, useState } from "react";
import _ from "lodash";
import api from "../feathersClient";

const currenYear = new Date().getFullYear();
const Dashboard = () => {
  const [year, setYear] = useState(currenYear);
  const [data, setData] = useState([])
  const [numCat1, setNumCat1] = useState([]);


  useEffect(() => {
    const updateData = async () => {
      if (year > 0) {
        try {
          const competition = await api.service("competitions").find({query: { year: year}})
            if(competition &&  !_.isEmpty(competition.data)){
                setData(competition.data[0].categories)
                let showNumber = []
                for(let i = 0;  i < _.size(competition.data[0].categories); i++){
                    const data = competition.data[0].categories[i]
                    const res = await api
                        .service("users")
                        .find({
                            query: {
                                category: data.name,
                                updatedAt: {
                                    $gte: new Date(`${year}-01-01`),
                                    $lt: new Date(`${year+1}-01-01`)
                                }
                            },
                        });
                    showNumber.push({total: res.total, name: data.name})
                }
                setNumCat1(showNumber)
           }

        } catch (e) {
          // pass
        }
      }
    };
    updateData();
  }, [year]);
    const yearsDiff = currenYear - 2021;
    const years = Array.from({ length: yearsDiff + 1 }, (_, i) => currenYear - i);
    console.log("yeardiff", years)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "1440px",
      }}
    >
      <h1>Bảng điều khiển</h1>
      <select
        style={{ alignSelf: "flex-end" }}
        defaultValue={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value={0}>Chọn năm</option>
          {years.map((year) => (<option value={year}>{year}</option>))}
      </select>
      <div
        style={{
          paddingTop: "120px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>Có {_.sumBy(numCat1, "total")} đơn vị tham gia dự giải</p>
        <div style={{ display: "flex", alignItems: "center" }}>
            {numCat1.map(item => {
                return (<div
                    style={{
                        marginLeft: 18,
                        marginRight: 18,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center"
                    }}
                >
                    <p>{item.total}</p>
                    <img style={{width: 150, height: 150}} src={"/cat.png"} />
                    <div>{item.name}</div>
                </div>)
            })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
