import * as React from "react";
import {
  ArrayInput,
  SimpleFormIterator,
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  required,
  DateInput,
  FileInput,
  FileField,
} from "react-admin";

const Title = ({ record }) => <span>Cuộc thi {record.year}</span>;

export const CompetitionEdit = (props) => {
  return (
    <Edit {...props} title={<Title />}>
      <SimpleForm redirect="list">
        <TextField source="year" validate={required()} />
        <ArrayInput source="categories">
          <SimpleFormIterator>
            <TextInput source="name" fullWidth />
            <FileInput
              source="winnersFile"
              maxSize={25000000}
              accept="application/pdf"
            >
              <FileField source="url" title="title" />
            </FileInput>
            <ArrayInput source="subcategories">
              <SimpleFormIterator>
                <TextInput
                  source="name"
                  fullWidth
                  style={{ marginTop: "16px" }}
                />
              </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="scoringcriteria">
              <SimpleFormIterator>
                <TextInput
                    source="name"
                    fullWidth
                    style={{ marginTop: "16px" }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="keyDates">
          <SimpleFormIterator>
            <TextInput source="name" validate={required()} />
            <DateInput source="date" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <FileInput
            source="keyInvite"
            maxSize={25000000}
            accept="application/pdf"
        >
          <FileField source="url" title="title" />
        </FileInput>
        <FileInput
            source="keySponsor"
            maxSize={25000000}
            accept="application/pdf"
        >
          <FileField source="url" title="title" />
        </FileInput>
        <FileInput
            source="keyQuyche"
            maxSize={25000000}
            accept="application/pdf"
        >
          <FileField source="url" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
