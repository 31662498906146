import * as React from 'react';
import {
    Create,
    CreateProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
    SelectInput,ImageField,
    required, ImageInput,
} from 'react-admin';
import { Box, CardContent, Divider, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';



export const SponsorCreate = (props) => {
    return (
        <Create {...props} actions={false}>
            <SimpleForm component={CustomLayout} redirect="show">
                <ImageInput source="image">
                    <ImageField source="src" title="title" />
                </ImageInput>
                <SelectInput source="sponsorType" choices={[
                    { id: 'commander', name: 'Bổ trợ chỉ đạo' },
                    { id: 'executor', name: 'Tổ chức thực hiện' },
                    { id: 'sponsor', name: 'Đơn vị tài trợ' },
                    { id: 'communicationsupport', name: 'Bảo trợ truyền thông' },
                ]} />
                <TextInput source="name" validate={required()} fullWidth />

                <CustomDivider />
            </SimpleForm>
        </Create>
    );
};

const CustomLayout = (props) => (
    <CardContent>
        <Box display="flex">
            <Box paddingTop={1}>
                <Avatar>
                    <BusinessIcon />
                </Avatar>
            </Box>
            <Box ml={2} flex="1" maxWidth={796}>
                {props.children}
            </Box>
        </Box>
    </CardContent>
);

const CustomDivider = () => (
    <Box mb={2}>
        <Divider />
    </Box>
);
