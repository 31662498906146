import React from "react";
import {
  ArrayInput,
  Edit,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import IconInput from "../../inputs/IconInput";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const configureQuill = (quill) =>
  quill.getModule("toolbar").addHandler("bold", function (value) {
    this.quill.format("bold", value);
  });

// IconInput popover shows outside the form.
const theme = createMuiTheme({
  overrides: {
    RaEdit: {
      overflow: "visible",
    },
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
  },
});

export default (props) => (
  <ThemeProvider theme={theme}>
    <Edit {...props} style={{ overflow: "visible" }}>
      <SimpleForm style={{ overflow: "visible" }}>
        <TextInput source="websiteTitle" validate={required()} fullWidth/>
        <Typography variant="h6" gutterBottom>
          Con số thống kê
        </Typography>
        <Box  display="flex" sx={{ flexWrap: 'wrap' }} fullWidth>
          <Box flex={1} p={1} >
            <TextInput source="statistic.seasons_number" isRequired fullWidth label={"Số mùa"}/>
          </Box>
          <Box flex={1} p={1}>
            <TextInput source="statistic.organizations_number" isRequired fullWidth label={"Số tổ chức, doanh nghiệp"}/>
          </Box>
          <Box flex={1} p={1}>
            <TextInput source="statistic.award_application_number" isRequired fullWidth label={"Số hồ sơ tham dự"}/>
          </Box>
          <Box flex={1} p={1}>
            <TextInput source="statistic.outstanding_number" isRequired fullWidth label={"Số sáng kiến chuyển đổi số"}/>
          </Box>
        </Box>
        <Box  display="flex" sx={{ flexWrap: 'wrap' }} fullWidth>
          <Box flex={1} p={1}>
            <TextInput source="statistic.seasons" isRequired fullWidth />
          </Box>
          <Box flex={1} p={1}>
            <TextInput source="statistic.organizations" isRequired fullWidth />
          </Box>
          <Box flex={1} p={1}>
            <TextInput source="statistic.award_application" isRequired fullWidth />
          </Box>
          <Box flex={1} p={1}>
            <TextInput source="statistic.outstanding" isRequired fullWidth />
          </Box>
        </Box>
        <RichTextInput
          source="footerContent"
          toolbar={[[{ header: [4, false] }]]}
          configureQuill={configureQuill}
        />
        <ArrayInput source="contactNumbers">
          <SimpleFormIterator>
            <TextInput source="name" validate={required()} />
            <TextInput source="number" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="socials">
          <SimpleFormIterator>
            <IconInput
              source="icon"
              initialValue="FaQuestion"
              validate={required()}
            />
            <TextInput source="url" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  </ThemeProvider>
);
