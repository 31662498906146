import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  SelectField,
} from "react-admin";
import decodeJwt from "jwt-decode";

const getToken = () => {
  return decodeJwt(localStorage.getItem("token"));
}

export default (props) => {

  const [filter, setFilter] = useState({ role: { $gte: 1 } });

  const [user, setUser] = useState({})

  const permissions = user ? user.role : undefined;
  useEffect(()=> {
    const user = getToken();
    setUser(user)
  },[]);

  useEffect(() => {
    if (permissions === 3) {
      setFilter({ role: { $gte: 1, $lt: 3 } });
    }
  }, [permissions]);

  return (
    <List
      {...props}
      filter={filter}
      {...(permissions < 4 ? { bulkActionButtons: false } : {})}
    >
      <Datagrid rowClick="show">
        <TextField source="username" />
        <TextField source="familyName" />
        <TextField source="givenName" />
        <TextField source="email" />
        <SelectField
          source="role"
          choices={[
            { id: 0, name: "Thành viên đăng ký" },
            { id: 1, name: "Giám khảo sơ khảo" },
            { id: 2, name: "Giám khảo chung khảo" },
            { id: 3, name: "Biên tập viên" },
            { id: 4, name: "Quản trị viên" },
            { id: 5, name: "Quản lý Kỹ thuật" },
          ]}
        />
      </Datagrid>
    </List>
  );
};
